<template>
    <div class="goods-lable-box" v-if="returnShow">
        <template v-for="(item, index) in textElements">
            <template v-if="item.showPop && shouldRender(item) && returnLabel_content(item).length>0&&token">
                <el-popover v-model="popoverStates[item.id]" placement="bottom-start"
                    :width="width" :trigger="trigger" :visible-arrow="false" popper-class="goods-label-popover">
                    <div class="content">
                        <p>{{ item.content }}信息：</p>
                        <template v-for="content_item in returnLabel_content(item)">
                            <div>
                                <p v-if="content_item.title" class="content_item_title">{{ content_item.title }}：</p>
                                <p class="content_item_value">{{ content_item.content }}</p>
                            </div>
                        </template>
                    </div>
                    <el-tag slot="reference" @click.stop="togglePopover(item)" size="mini" :type="item.type"
                        :class="item.className" :key="index">{{ item.content }}</el-tag>
                </el-popover>
            </template>
            <template v-else>
                <el-tag size="mini" v-if="shouldRender(item)" :type="item.type" :class="item.className" :key="index">{{
                    item.content }}</el-tag>
            </template>
        </template>
        <div @click.stop="closeAll" class="tag_bg" v-if="returnShowBg&&trigger!=='hover'"></div>
    </div>

</template>

<script>
import { mapGetters } from "vuex"
export default {
    props: {
        width: {
            type: String,
            default: '200'
        },
        goods: {
            type: Object,
            default: () => { }
        },
        
        trigger:{
            type: String,
            default: 'hover' // manual 通过点击控制进行打开
        }
    },
    data() {
        return {
            tagOptions: [],
            popoverStates: {
                'country-code': false,
                'get-free': false,
                'seckill': false,
                'discount': false,
                'wholesale': false,
                'out-of-stock': false
            }, // 存储弹窗状态
        }
    },
    computed: {
        ...mapGetters(["token"]),
        textElements() {
            return [
                {
                    id: 'country-code',
                    condition: () => !!this.goods.attr && !!this.goods.attr.attr_country_code,
                    className: 'country-code',
                    content: '国家码',
                    type: 'primary',
                },
                {
                    id: 'get-free',
                    condition: () => !!this.goods.is_getfree || !!this.goods.get_free_goods,
                    className: 'get-free',
                    content: '换购',
                    showPop: true,
                    type: 'error',
                },
                {
                    id: 'seckill',
                    condition: () => this.goods.promotion_label === 'seckill',
                    className: 'promotion',
                    content: '秒杀',
                    type: 'error',
                },
                {
                    id: 'discount',
                    condition: () => this.goods.promotion_label === 'discount',
                    className: 'promotion',
                    content: '折扣',
                    type: 'error',
                },
                {
                    id: 'wholesale',
                    condition: () => this.goods.promotion_label === 'wholesale' || !!this.goods.wholesale_goods_id,
                    className: 'promotion',
                    content: '批发',
                    showPop:true,
                    type: 'error',
                },
                {
                    id: 'out-of-stock',
                    condition: () => this.goods.stock === 0,
                    className: 'out-of-stock',
                    content: '暂无库存',
                    type: 'error',
                },
                // {
                // 	condition: () => this.storeToken && !!this.goods.sale_control && !this.goods.sale_control.is_member_by,
                // 	className: 'not-buy-msg',
                // 	content: this.goods?.sale_control?.not_buy_msg,
                // 	type: 'error',
                // }
            ];
        },
        returnShow() {
            let arr = this.textElements.filter(item => {
                if(this.shouldRender(item)){
                    return item;
                }
            });
            if(arr.length>0){
                return true;
            }else{
                return false;
            }
        },
        returnShowBg() {
            if (!this.popoverStates) {
                return false;
            }
            for (let key in this.popoverStates) {
                if (this.popoverStates[key]) {
                    return true;
                }
            }
            return false;
        }
    },
    methods: {
        togglePopover(info) {
            if (this.trigger === 'hover') {
                return;
            }
            this.popoverStates[info.id] = !this.popoverStates[info.id];
        },
        returnLabel_content(info) {
            switch (info.id) {
                case 'get-free':
                    return this.returnFreeText();
                 case 'wholesale':
                    return this.returnwholesaleText();
                default:
                    return []
            }
        },
        returnwholesaleText(){
             let textArr = [];
            let text_bool = (this.goods.promotion_info?.price_json && this.goods.promotion_info.price_json.length>0) || this.goods.price_json
            if(!text_bool){
                return textArr;
            }
            let priceJon =  this.goods.promotion_info?.price_json || JSON.parse( this.goods.price_json)
            textArr =priceJon.map((item)=>{
              return {title:``,content:`${item.num}${this.goods.unit ? this.goods.unit : '个'}起批，限购数量：${item.limit_num}，价格：¥${item.price}`}
            })
            return textArr;
        },
        // 返回换购相关信息
        returnFreeText() {
            let textArr = [];
            let text_bool = (!this.goods||!this.goods.get_free_goods||!this.goods.get_free_goods.getFreeGoods);
            if(text_bool){
                return textArr;
            }
            let bool = (this.goods.get_free_goods.is_ladder > 0)
            this.goods.get_free_goods.getFreeGoods.forEach(item => {
                if (bool) {
                    if (typeof item.ladder_json === 'string') {
                        item.ladder_json = JSON.parse(item.ladder_json)
                    }
                    let arr = [];
                    item.ladder_json.forEach((item1, index) => {
                        arr.push(`本商品x${item1.mainRang}可换${item1.rang}${item.goods.unit ? item.goods.unit : '个'}每${item.goods.unit ? item.goods.unit : '个'}￥${item1.rangPrice}`)
                    })
                    let str = arr.join('；')
                    let textObj = {title:item.goods.goods_name,content:str}
                    textArr.push(textObj);
                } else {
                    let str = ''
                    if (item.limit > 0) {
                        str =`本商品x${item.count}可换${item.num}${item.goods.unit ? item.goods.unit : '个'}每${item.goods.unit ? item.goods.unit : '个'}￥${item.price}`;
                    } else {
                        str = `本商品x${item.count}可换${item.num}${item.goods.unit ? item.goods.unit : '个'}每${item.goods.unit ? item.goods.unit : '个'}￥${item.price},换购数量无上限`;
                    }
                    let textObj = {title:item.goods.goods_name,content:str}
                    textArr.push(textObj);
                }
            })
            return textArr;
        },
        shouldRender(item) {
            return item.condition();
        },
        closeAll() {
            for (let key in this.popoverStates) {
                this.popoverStates[key] = false;
            }
        }
    }
}
</script>
<style>
.goods-label-popover {
    padding: 6px;
    margin-top: 6px !important;
    box-sizing: border-box;
    border: 1px solid #ff547b;
}
</style>
<style lang="scss" scoped>
.goods-lable-box {
    display: flex;
    align-items: center;
    gap: 6px;
    >span{
        display: flex;
    }
}
.content{
    display: flex;
    flex-direction: column;
    gap: 6px;
    >p:nth-child(1){
        font-size: 14px;
        font-weight: bold;
        color: #333;
    }
    >div:not(:first-child){
        display: flex;
        flex-direction: column;
        gap: 6px;
        >p:nth-child(1){
            font-size: 12px;
            line-height: 14px;
            color: #ff547b;
        }
        >p:nth-child(2){
            font-size: 12px;
            line-height: 14px;
            color: #787878;
        }
    }
    
}
.tag_bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: 9999;
    cursor: default;
}
</style>