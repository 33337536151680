<script>
    import { hasPermission, priceText, getGoodsPromotion } from "@/utils/permission"

    export default {
        name: "price",
        props: {
            goods: {
                required: true,
                type: Object
            }
        },
        methods: {
            showPrice(data) {
                let price = data.discount_price;
                if (data.member_price && parseFloat(data.member_price) < parseFloat(price)) price = data.member_price;
                return price;
            },
        },
        computed: {
            hasPermission() {
                return hasPermission(this.goods)
            },
            priceText() {
                let value = this.goods.discount_price;
                if (this.promotion === "wholesale" && this.goods.min_price && this.goods.max_price) {
                    if(this.goods.min_price == this.goods.max_price){
                        value = this.goods.max_price
                    }else{
                        value = this.goods.min_price + ' ~ ￥' + this.goods.max_price
                    }
                   
                }
                return priceText(this.goods, value)
            },
            discountPrice() {
                if (this.promotion === "wholesale") {
                    return priceText(this.goods, this.goods.price)
                }
                return this.goods.price
            },
            promotion() {
                return getGoodsPromotion(this.goods)?.type
            },
            showDiscountPrice() {
                return this.promotion !== "getfree" && this.promotion !== "wholesale" && this.hasPermission && this.promotion
            },
            // 计算是否显示客户价格图标
            showMemberIcon() {
                // 只有在有权限的情况下才显示客户价格图标
                return this.hasPermission && this.goods.member_price && this.goods.member_price == this.showPrice(this.goods);
            },
            // 计算是否显示促销图标
            showPromotionIcon() {
                return this.hasPermission && this.goods.promotion_type == 1;
            },
        }
    }
</script>

<template>
    <span style="display: flex;align-items: center;gap: 6px;">
        <span style="color: #EE443F;font-size: 16px;">
            <span style="font-size: 14px">￥</span>
            <span>{{ priceText }}</span>
        </span>
        <img v-if="showMemberIcon" :src="$util.img('/upload/uniapp/category/menber_icon.png')"/>
        <img v-else-if="showPromotionIcon" :src="$util.img('/upload/uniapp/category/discount_icon.png')"/>
        <span v-if="showDiscountPrice" style="color: #969696; font-size: 14px; text-decoration: line-through">
            <span>￥{{ discountPrice }}</span>
        </span>
    </span>
</template>

<style scoped lang="scss">
    img {
        width: 40px;
    }
</style>
