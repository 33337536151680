var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","gap":"12px"}},[(_vm.showNum)?_c('shareCounter',{attrs:{"number":_vm.goods.min_buy,"goodsSkuDetail":{
            min_buy: _vm.goods.min_buy,
            package_num: _vm.goods.package_num,
            stock: _vm.goods.goods_stock,
            is_member_by: _vm.goods.sale_control?.is_member_by,
            sale_control_name: _vm.goods.sale_control?.sale_control_name

        },"size":"mini"},on:{"upnumber":_vm.upnumber}}):_vm._e(),_c('div',{staticClass:"btn",class:{disabled: _vm.buyBtnAuth.disabled},on:{"click":function($event){$event.stopPropagation();return _vm.joinCart.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.buyBtnAuth.text))])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }