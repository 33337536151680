<template>
    <div style="display: flex;gap: 12px">
        <shareCounter
            v-if="showNum"
            :number="goods.min_buy"
            :goodsSkuDetail="{
                min_buy: goods.min_buy,
                package_num: goods.package_num,
                stock: goods.goods_stock,
                is_member_by: goods.sale_control?.is_member_by,
                sale_control_name: goods.sale_control?.sale_control_name

            }"
            @upnumber="upnumber"
            size="mini"
        ></shareCounter>
        <div class="btn" :class="{disabled: buyBtnAuth.disabled}" @click.stop="joinCart">{{ buyBtnAuth.text }}</div>
    </div>
</template>

<script>
    import { buyBtnAuth } from "@/utils/permission"
    import shareCounter from "@/components/shareCounter.vue"
    import { onTip } from "@/utils/tips"

    export default {
        name: "add-cart",
        components: { shareCounter },
        props: {
            goods: {
                required: true,
                type: Object
            },
            showNum: {
                type: Boolean,
                default: true,
            },
            text: {
                type: String,
                default: "加入购物车",
            }
        },
        data() {
            return {
                number: 1
            }
        },
        computed: {
            buyBtnAuth() {
                return buyBtnAuth(this.goods, this.text)
            }
        },
        methods: {
            upnumber(val) {
                console.log("upnumber", val)
                this.number = val
            },
            joinCart() {
                const item = this.goods;
                if (onTip(item.sale_control.view_price, item.sale_control.is_member_by,this,item.sale_control.sale_control)) return
                if (item.min_buy > this.number) {
                    this.number = item.min_buy;
                }
                this.$store
                    .dispatch("cart/add_to_cart", {
                        site_id: item.site_id,
                        sku_id: item.sku_id,
                        num: this.number
                    })
                    .then(res => {
                        if (res.code === 0) {
                            this.$message({
                                message: "加入购物车成功",
                                type: "success"
                            })
                        }
                    })
                    .catch(err => {
                        if (err.message === "token不存在") {
                            this.$router.pushToTab("/login")
                        } else {
                            this.$message.error(err.message)
                        }
                    })
            },
        }
    }
</script>


<style scoped lang="scss">
    :deep(.el-input-number--mini) {
        width: 100px;
        .el-input__inner {
            height: 28px;
            line-height: 28px;
        }
        .el-input-number__decrease {
            width: 27px !important;
        }
        .el-input-number__increase {
            width: 27px !important;
        }
    }
    .btn {
        padding: 0 12px;
        height: 27px;
        background: linear-gradient(44deg, #F17F3A 0%, rgba(240, 120, 82, 0.07) 28%, rgba(240, 115, 100, 0) 49%, rgba(247, 92, 111, 0.47) 73%, #FF427C 100%), #FF638B;
        border-radius: 4px;
        line-height: 27px;
        text-align: center;
        font-size: 12px;
        color: #FFFFFF;
        cursor: pointer;
        &.disabled {
            background: #c6c5c5;
            cursor: not-allowed;
        }

    }
</style>
