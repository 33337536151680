<template>
	<div
		:style="customGap"
		:class="['hoverBox','list-goods-item',{'up-to-down-layout':upDownLayout},{'has-bottom-border':bottomBorder},{'list-goods-item-last':lastFlag}]"
		@click.stop="toDetail()">
		<img class="goods-image" :style="imgSize"
		       :src="$util.img(goods.sku_image || goods.goods_image)"
		       @error="goodsImageError()" mode="widthFix"/>
		<div class="goods_detail">
            <div class="goods_name multi-hidden">
                <goodsLabel :goods="goods"></goodsLabel>
                <div class="goodsName">{{ goods.goods_name }}</div>
            </div>
            <div style="display: flex;align-items: center;gap: 6px;">
                <slot name="price"></slot>
                <price v-if="!$slots.price" :goods="goods"></price>
            </div>
			<div v-if="goods.is_jc!=undefined && goods.traceability_code!=undefined" class="medicine_code">
				<div class="code_cc" v-if="false"><span>国家码</span></div>
				<div class="code_cb" v-if="goods.is_jc==1"><span>集采</span></div>
				<div class="code_tc" v-if="goods.traceability_code==1"><span>追溯码</span></div>
			</div>
			<ctrlShowText :info="goods" :showLimit="showLimit"></ctrlShowText>
            <div>
                <slot name="addCart"></slot>
                <add-cart v-if="!$slots.addCart" :goods="goods"></add-cart>
            </div>
		</div>
	</div>
</template>
<script>

import Price from "./price.vue"
import AddCart from './add-cart.vue'
import ctrlShowText from "../ctrl-show-text/ctrl-show-text.vue";
import { buyBtnAuth} from "@/utils/permission"
import goodsLabel from "../goods-label/goods-label.vue";
export default {
	name: "goods-list-item",
    components: { AddCart, Price ,ctrlShowText , goodsLabel},

	props: {
		goods: {
			type: Object,
			required: true,
			default: ''
		},
		lastFlag: {
			type: Boolean,
			default: false
		},
		imageSize: {
			type: [Number, String],
			default: 200
		},
		upDownLayout: {
			type: Boolean,
			default: false
		},
		bottomBorder: {
			type: Boolean,
			default: true
		},
		gap: {
			type: [Number, String],
			default: 10
		},
		addCart: {
			type: Boolean,
			default: true
		},
		showLimit:{
			type: Boolean,
			default: false
		}
	},
	data() {
		return {}
	},
	methods: {
		toDetail() {
            if(this.$listeners.click) {
                this.$emit('click');
                return;
            }
            const hasPermissionInfo = buyBtnAuth(this.goods);
			if (!hasPermissionInfo.disabled) {
				this.$router.pushToTab("/sku-" + this.goods.sku_id)
			} else {
              this.$message.error(hasPermissionInfo.text)
			}
		},
		// 计算是否有权限查看价格

		shouldRender(item) {
			return item.condition();
		},
		goodsImageError() {
			this.goods.sku_image = this.$util.getDefaultImage().default_goods_img;
		}
	},
	computed: {
		customGap(){
			return `gap:${this.gap}px`
		},
		imgSize() {
			return `height: ${this.imageSize}px`
		},
		attributes() {
			return [
				{name: '', value: this.goods.attr.attr_factory},
				{name: '规格', value: this.goods.attr.attr_specs},
				{name: '效期', value: this.goods.attr.attr_validity.trim() || '-'},
				{name: '库存', value: this.goods.stock_name || this.goods.stock || this.goods.goods_stock}
			]
		},
		storeToken() {
			return this.$store.state.member.token;
		},
		textElements() {
			return [
				{
					condition: () => !!this.goods.attr && !!this.goods.attr.attr_country_code,
					className: 'country-code',
					content: '国家码',
					type: 'primary',
				},
				{
					condition: () => !!this.goods.is_getfree || !!this.goods.get_free_goods,
					className: 'get-free',
					content: '换购',
					type: 'error',
				},
				{
					condition: () => this.goods.promotion_label === 'seckill',
					className: 'promotion',
					content: '秒杀',
					type: 'error',
				},
				{
					condition: () => this.goods.promotion_label === 'discount',
					className: 'promotion',
					content: '折扣',
					type: 'error',
				},
				{
					condition: () => !!this.goods.wholesale_goods_id,
					className: 'promotion',
					content: '批发',
					type: 'error',
				},
				{
					condition: () => this.goods.stock === 0,
					className: 'out-of-stock',
					content: '暂无库存',
					type: 'error',
				},
				// {
				// 	condition: () => this.storeToken && !!this.goods.sale_control && !this.goods.sale_control.is_member_by,
				// 	className: 'not-buy-msg',
				// 	content: this.goods?.sale_control?.not_buy_msg,
				// 	type: 'error',
				// }
			];
		}
	}
}
</script>

<style>
.list-goods-item:last-of-type {
	border: none !important;
	margin: 0;
}
</style>

<style scoped lang="scss">
.medicine_code{
	display: flex;
	align-items: center;
	gap: 3px;
	>div{
		display: flex;
		align-items: center;
		justify-content: center;
		>span{
			font-weight: 400;
			font-size: 11px;
			line-height: 16px;
		}
	}
	.code_cc{
		width: 39px;
		height: 16px;
		border-radius: 6px;
		border: 1px solid #6DD4C4;
		>span{
			color: #6DD4C4;
		}
	}
	.code_cb{
		width: 31px;
		height: 16px;
		border-radius: 6px;
		border: 1px solid #FFAA00;
		>span{
			color: #FFAA00;
		}
	}
	.code_tc{
		width: 41px;
		height: 16px;
		border-radius: 6px;
		border: 1px solid #F55E82;
		>span{
			color: #F55E82;
		}
	}
}
.coupon_price {
	display: inline-block;
	text-indent: 5px;
	line-height: normal;

	.ticket_font {
		color: #909399;
		margin-right: 5px;
	}

	.small_text {
		font-size: 16px;
	}

	.smaller_text {
		font-size: 14px
	}
}

.list-goods-item {
	flex-grow: 1;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding-bottom: 10px;
	margin-bottom: 10px;
    cursor: pointer;
}

.list-goods-item.has-bottom-border {
	border-bottom: 1px solid #ccc;
}

.list-goods-item.up-to-down-layout {
	gap: 10px;
	flex-direction: column;
	justify-content: space-between;
	padding-bottom: 0;
	margin-bottom: 0;


}

.list-goods-item-last {
	border: none !important;
	margin: 0;
}

.goods-image {
	flex-shrink: 0;
}

/* 多行超出隐藏 */
.multi-hidden {
	word-break: break-all;
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}

.goods_detail {
    display: flex;
    flex-direction: column;
	position: relative;
	width: 100%;
	flex-grow: 1;
    justify-content: space-between;
    gap: 6px;
}

.goods_name {
	font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    display: flex;
    align-items: center;
    gap: 6px;
}

.label-elements {
	display: flex;
	flex-wrap: wrap;
	gap: 6px
}

.goods_price {
	position: relative;
	display: flex;
	flex-direction: column;

    color: red;
    font-size: 16px;
    line-height: 24px;
	.goods-price-box image {
		margin-left: 4px;
		width: 62px;
		height: 24px;
	}

	.price-icon {
		margin-right: 2px;

	}

	.sale-price {

		margin-bottom: 10px;
	}

	.line-price {

		text-decoration: line-through;
	}

	.careBox {
		padding: 50px;
		position: absolute;
		bottom: -20px;
		right: -50px;
		height: 52px;
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

.goods-info {

    font-size: 11px;
    color: #787878;


	.goods_attr_info {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

.add-goods-btn {
	position: absolute;
	bottom: 0;
	right: 0;
}
</style>
